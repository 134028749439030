import heroImg from './assets/pikachu7.png';
import navbarImg from './assets/memeLogo1.png';
import aboutImg from './assets/pikachu3.png';
import backgroundAbout from './assets/yellowBg1.jpg';
import backgroundImage from './assets/pikachuBg3.jpg';
import telegram from "./assets/svgs/icons8-telegram-app.svg";
import twitter from "./assets/svgs/icons8-twitter.svg";
import discord from "./assets/svgs/icons8-discord.svg";
import tokenomiksImg from './assets/pikachu8.png';
import backgroundTokenomics from './assets/whiteBg3.jpg';
import backgroundHtb from './assets/whiteBg3.jpg';
import htbImg1 from './assets/htb1.png';
import htbImg2 from './assets/htb2.png';
import htbImg3 from './assets/htb3.png';
import htbImg4 from './assets/htb4.png';
import htbImg5 from './assets/htb5.png';


export const data = {
  navbar: {
    title: "$PIKACHU",
    image: navbarImg,
    menus: [
      { text: "Telegram", link: "#about" },
      { text: "Twitter", link: "#footer" }
    ],
    button: [
        {text: "Buy $PKC", link: "/"},
        {text: "Links", link: "#footer"},
    ],
    hamburger: true
  },
  hero: {
    title: "$PIKACHU",
    description: "Join the revolution of fun and community-driven finance with $PIKACHU, the meme coin that's taking the crypto world by storm! Dive into our vibrant community and discover how you can be part of this exciting journey.",
    buttons: [
      { text: "Telegram", link: "/" },
      { text: "Twitter", link: "/" }
    ],
    image: heroImg,
    backgroundImage: backgroundImage
  },
  tokenomics: {
    title: "Tokenomics",
    image: tokenomiksImg,
    backgroundImage: backgroundTokenomics,
    details: [
      {
        title: "$PKC",
        description: "Ticker",
      },
      {
        title: "Ethereum",
        description: "Network",
      },
      {
        title: "No Tax 0/0",
        description: "Texes",
      },
      {
        title: "100% Burned",
        description: "Liquidity",
      },
      {
        title: "100,000,000",
        description: "Total Supply",
      },
      {
        title: "Renounced",
        description: "Contract Ownership",
      },
    ],
  },
  about: {
    title: "PIKACHU",
    subtitle: "About us",
    description: [
      '$PIKACHU isn’t just another meme coin; it’s a community-powered revolution. Inspired by the beloved world of anime and powered by Ethereum\'s robust blockchain, $PIKACHU combines nostalgia with innovative tokenomics and rapid growth potential. Whether you’re here for the laughs or the gains, $PIKACHU is designed for those who value humor and a shared mission to make memes great again!',
    ],
    image: aboutImg,
    backgroundImage: backgroundAbout,
  },
  tradeLink: 'https://dexscreener.com/solana/BBeZSVTCWMQemzZd1qQuoa4G7ifDhodaC9qvZ6o9nH11?embed=1&theme=dark&trades=0&info=0',
  contractAddress: 'BBeZSVTCWMQemzZd1qQuoa4G7ifDhodaC9qvZ6o9nH11',
  howToBuy: {
    title: "How to Buy",
    steps: [
      {
        title: "Step 1: Download a Crypto Wallet",
        description: "Download a wallet like MetaMask or Trust Wallet, which supports Ethereum-based tokens. Follow the instructions to create a secure wallet and store your passphrase safely.",
        image: htbImg1,
        backgroundImage: null,
      },
      {
        title: "Step 2: Fund your wallet",
        description: "Purchase $ETH through your wallet or transfer it from an exchange. This ETH will be used to buy $PikaDoge.",
        image: htbImg2,
        backgroundImage: null,
      },
      {
        title: "Step 3: Visit our wallet listing partners",
        description: "Transfer SOL to your wallet",
        image: htbImg3,
        backgroundImage: null,
      },
      {
        title: "Step 4: Swap $ETH for $PIKACHU",
        description: "Trade your $ETH for $PIKACHU. Copy and paste the contract address to find $PIKACHU.",
        image: htbImg4,
        backgroundImage: null,
      },
      {
        title: "Step 5: CELEBRATE!",
        description: "Join us on Discord or Telegram to connect with fellow Pikachu fans and stay updated on the latest happenings.",
        image: htbImg5,
        backgroundImage: null,
      },
    ],
    backgroundImage: backgroundHtb,
  },
  infiniteScroll: [
    {
      text: "wolf token",
      image: heroImg
    },
    {
      text: "wolf token",
      image: heroImg
    },
    {
      text: "wolf token",
      image: heroImg
    },
    {
      text: "wolf token",
      image: heroImg
    },
  ],
  footer: {
    title: "",
    buttons: [
      { text: telegram, link: "/" },
      { text: twitter, link: "/" },
      { text: discord, link: "/" },
    ],
    appName: 'PIKACHU',
    paragraph: "Join our community to stay up to date with the latest news, events, and giveaways!",
  },
  theme: {
    primary: "#FFCC00",
    primaryLight: "#CBDCEB",
    secondary: "#9D0B0B",
    secondaryLight: "rgba(190,183,164,0.93)",
    accent: "#fff",
    light: "#fefefe",
    dark: "#1c1c1c",
  }
};